import {
    PhHouse,
    PhMagnifyingGlass,
    PhSignOut,
    PhClipboard,
    PhMetaLogo,
    PhInstagramLogo,
    PhSignIn,
    PhUserPlus,
    PhShare,
    PhUser,
    PhPaperPlaneTilt,
    PhFloppyDisk,
    PhKey,
    PhFileText,
    PhCreditCard
} from '@phosphor-icons/vue'

export default defineNuxtPlugin((nuxtApp) => {    
    nuxtApp.vueApp.component('PhHouse', PhHouse)
    nuxtApp.vueApp.component('PhMagnifyingGlass', PhMagnifyingGlass)
    nuxtApp.vueApp.component('PhSignOut', PhSignOut)
    nuxtApp.vueApp.component('PhClipboard', PhClipboard)
    nuxtApp.vueApp.component('PhMetaLogo', PhMetaLogo)
    nuxtApp.vueApp.component('PhInstagramLogo', PhInstagramLogo)
    nuxtApp.vueApp.component('PhSignIn', PhSignIn)
    nuxtApp.vueApp.component('PhUserPlus', PhUserPlus)
    nuxtApp.vueApp.component('PhShare', PhShare)
    nuxtApp.vueApp.component('PhUser', PhUser)
    nuxtApp.vueApp.component('PhPaperPlaneTilt', PhPaperPlaneTilt)
    nuxtApp.vueApp.component('PhFloppyDisk', PhFloppyDisk)
    nuxtApp.vueApp.component('PhKey', PhKey)
    nuxtApp.vueApp.component('PhFileText', PhFileText)
    nuxtApp.vueApp.component('PhCreditCard', PhCreditCard)
})