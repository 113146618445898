
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as about8ntv4eJLPHMeta } from "/var/www/boligkartoteket-frontend/pages/about.vue?macro=true";
import { default as accountazM9eYfISqMeta } from "/var/www/boligkartoteket-frontend/pages/administration/account.vue?macro=true";
import { default as change_45passwordbAw0VYyPBxMeta } from "/var/www/boligkartoteket-frontend/pages/administration/change-password.vue?macro=true";
import { default as dashboardj4PIZwJCUBMeta } from "/var/www/boligkartoteket-frontend/pages/administration/dashboard.vue?macro=true";
import { default as invoicesPSIxXy5nASMeta } from "/var/www/boligkartoteket-frontend/pages/administration/invoices.vue?macro=true";
import { default as payment_45methodvHSe52o7t5Meta } from "/var/www/boligkartoteket-frontend/pages/administration/payment-method.vue?macro=true";
import { default as indexrzbUKVcaIRMeta } from "/var/www/boligkartoteket-frontend/pages/administration/residences/[id]/index.vue?macro=true";
import { default as contactufBwffxWE2Meta } from "/var/www/boligkartoteket-frontend/pages/contact.vue?macro=true";
import { default as cookieshG7DVQkSLtMeta } from "/var/www/boligkartoteket-frontend/pages/cookies.vue?macro=true";
import { default as faqkDjv9A6Md8Meta } from "/var/www/boligkartoteket-frontend/pages/faq.vue?macro=true";
import { default as health_45checkh9w0n9T3xTMeta } from "/var/www/boligkartoteket-frontend/pages/health-check.vue?macro=true";
import { default as help0vY2rgz4XfMeta } from "/var/www/boligkartoteket-frontend/pages/help.vue?macro=true";
import { default as indexybu32vO3jZMeta } from "/var/www/boligkartoteket-frontend/pages/index.vue?macro=true";
import { default as loginrJdyvHmtJQMeta } from "/var/www/boligkartoteket-frontend/pages/login.vue?macro=true";
import { default as privacySDbFnScUXSMeta } from "/var/www/boligkartoteket-frontend/pages/privacy.vue?macro=true";
import { default as productsFvOQtKPzhaMeta } from "/var/www/boligkartoteket-frontend/pages/products.vue?macro=true";
import { default as reset_45passwordpUs7q8xuTnMeta } from "/var/www/boligkartoteket-frontend/pages/reset-password.vue?macro=true";
import { default as indexIqTfENR1c2Meta } from "/var/www/boligkartoteket-frontend/pages/residences/[id]/index.vue?macro=true";
import { default as searchtj1Z84TvuIMeta } from "/var/www/boligkartoteket-frontend/pages/search.vue?macro=true";
import { default as signupGs2NswcxH6Meta } from "/var/www/boligkartoteket-frontend/pages/signup.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/boligkartoteket-frontend/pages/about.vue")
  },
  {
    name: "administration-account",
    path: "/administration/account",
    meta: accountazM9eYfISqMeta || {},
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/account.vue")
  },
  {
    name: "administration-change-password",
    path: "/administration/change-password",
    meta: change_45passwordbAw0VYyPBxMeta || {},
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/change-password.vue")
  },
  {
    name: "administration-dashboard",
    path: "/administration/dashboard",
    meta: dashboardj4PIZwJCUBMeta || {},
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/dashboard.vue")
  },
  {
    name: "administration-invoices",
    path: "/administration/invoices",
    meta: invoicesPSIxXy5nASMeta || {},
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/invoices.vue")
  },
  {
    name: "administration-payment-method",
    path: "/administration/payment-method",
    meta: payment_45methodvHSe52o7t5Meta || {},
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/payment-method.vue")
  },
  {
    name: "administration-residences-id",
    path: "/administration/residences/:id()",
    meta: indexrzbUKVcaIRMeta || {},
    component: () => import("/var/www/boligkartoteket-frontend/pages/administration/residences/[id]/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/var/www/boligkartoteket-frontend/pages/contact.vue")
  },
  {
    name: "cookies",
    path: "/cookies",
    component: () => import("/var/www/boligkartoteket-frontend/pages/cookies.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/var/www/boligkartoteket-frontend/pages/faq.vue")
  },
  {
    name: "health-check",
    path: "/health-check",
    component: () => import("/var/www/boligkartoteket-frontend/pages/health-check.vue")
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/var/www/boligkartoteket-frontend/pages/help.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/boligkartoteket-frontend/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/var/www/boligkartoteket-frontend/pages/login.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/var/www/boligkartoteket-frontend/pages/privacy.vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/var/www/boligkartoteket-frontend/pages/products.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/var/www/boligkartoteket-frontend/pages/reset-password.vue")
  },
  {
    name: "residences-id",
    path: "/residences/:id()",
    component: () => import("/var/www/boligkartoteket-frontend/pages/residences/[id]/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/var/www/boligkartoteket-frontend/pages/search.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/var/www/boligkartoteket-frontend/pages/signup.vue")
  }
]