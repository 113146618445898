import revive_payload_client_4sVQNw7RlN from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/var/www/boligkartoteket-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/boligkartoteket-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import strapi_m0ehjFxJWT from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/strapi/dist/runtime/plugins/strapi.js";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import phosphorvue_NFtQ0rHri6 from "/var/www/boligkartoteket-frontend/plugins/phosphorvue.js";
import ssg_detect_3fHkBxLtv0 from "/var/www/boligkartoteket-frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  strapi_m0ehjFxJWT,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  phosphorvue_NFtQ0rHri6,
  ssg_detect_3fHkBxLtv0
]